import type {AxiosResponse, AxiosError} from 'axios';
import axios from 'axios';
import type VueI18n from 'vue-i18n';
import store from '@/store';
import Swal from 'sweetalert2';
import i18n, { hasKey } from '@/i18n';
import {showError, showWarning, showSuccess} from '../notification';

axios.interceptors.request.use((config: any) => {
  const params = new URLSearchParams(config.params);
  params.append('origin', 'Grafica');
  config.params = params;
  config.withCredentials = true;

  return config;
  // eslint-disable-next-line
}, (error: any) => Promise.reject(error));

function handleRequestResponseMessages (messages: Record<string, any>): void {
  if (messages.errors) {
    messages.errors.forEach((item: Record<string, any>) => {
      showError({message: hasKey(item.code as string) ? i18n.t(item.code as string, item.details as VueI18n.Values | undefined) : item.message});
    });
  }
  if (messages.error) {
    let message = messages.error.code && hasKey(messages.error.code as string) ? i18n.t(messages.error.code as string, messages.error.details as VueI18n.Values | undefined) : messages.error.message;
    // showError({message: hasKey(messages.error.code as string) ? i18n.t(messages.error.code as string, messages.error.details as VueI18n.Values | undefined) : messages.error.message});
    message = message.replaceAll('\n', '<br />');
    Swal.fire({
      icon: 'error',
      title: 'Aviso',
      html: message
    });
  }
  if (messages.warnings) {
    messages.warnings.forEach((item: Record<string, any>) => {
      showWarning({message: hasKey(item.code as string) ? i18n.t(item.code as string, item.details as VueI18n.Values | undefined) : item.message});
    });
  }
  if (messages.warning) {
    showWarning({message: hasKey(messages.warning.code as string) ? i18n.t(messages.warning.code as string, messages.warning.details as VueI18n.Values | undefined) : messages.warning.message});
  }
  if (messages.informations) {
    messages.informations.forEach((item: Record<string, any>) => {
      showSuccess({message: hasKey(item.code as string) ? i18n.t(item.code as string, item.details as VueI18n.Values | undefined) : item.message});
    });
  }
  if (messages.information) {
    showSuccess({message: hasKey(messages.information.code as string) ? i18n.t(messages.information.code as string, messages.information.details as VueI18n.Values | undefined) : messages.information.message});
  }
}

axios.interceptors.response.use((response: AxiosResponse) => {
  if (response.data?.messages) {
    handleRequestResponseMessages(response.data.messages as Record<string, any>);
  }

  return response;
}, (error: AxiosError) => {
  if (error.response?.data?.messages) {
    const isLoggedUser = error.response.config.url === '/account-logged-user';
    if (!isLoggedUser) {
      handleRequestResponseMessages(error.response.data.messages as Record<string, any>);
    }
  }

  if (error.request.status === 401) {
    if (process.env.VUE_APP_USE_PLATFORM_LOGIN_REDIRECT === 'true') {
      window.location.assign(`${process.env.VUE_APP_SIGN_IN_URL}?redirectUrl=${btoa(window.location.href)}&origin=Grafica`);
    } else {
      store.dispatch('auth/toggleSignInPageVisibility', { value: true });
    }
  }

  throw new Error(error.message);
});
